import {
  BarcodeScanner,
  BarcodeFormat
} from '@capacitor-mlkit/barcode-scanning';

import { searchPoint } from '@/store/tsSearch'
import Alert from './alert'

const stopBarcodeScan = async () => {
  await BarcodeScanner.stopScan();
  document.querySelector('body')?.classList.remove('barcode-scanner-active');
};

const scanSingleBarcode = async ({ type }) => {
  return new Promise(async resolve => {
    // type is enumeration of barcodeFormat, as at v7 options are
    // Aztec, Codabar, Code39, Code93, Code128, DataMatrix, Ean8, Ean13, Itf, Pdf417, QrCode, UpcA, UpcE
    if(!type) type = 'QrCode'
    if(type !== 'all' && !BarcodeFormat[type]) {
      console.log('invalid barcode type', type)
      return false
    }
    
    // The camera is visible behind the WebView, so that you can customize the UI in the WebView.
    // We have to hide all elements that should not be visible.
    document.querySelector('body')?.classList.add('barcode-scanner-active');

    // Set up scan options
    const scanOptions = {}
    if(type !== 'all') scanOptions.formats = [BarcodeFormat[type]]
    
    // For web implementation, specify the video element
    const videoElement = document.getElementById('barcode-scanner-video');
    if (videoElement) {
      scanOptions.videoElement = videoElement;
    }

    const listener = await BarcodeScanner.addListener(
      'barcodeScanned',
      async result => {
        await listener.remove();
        await stopBarcodeScan();
        resolve(result.barcode);
      },
    );

    await BarcodeScanner.startScan(scanOptions);
  });
};

export default {
    data() {
      return {
        $nfcScan: false,
        $nfcCallbackKey: null,
      }
    },
    mixins: [ Alert ],
    computed: {
      $nfc() {
        let result = {
          status: this.$store.state.Nfc.nfcStatus,
          state: this.$store.state.Nfc
        }

        let sa = (this.$schema.attributes || []).find((a) => {
          if(a.type === 'code' && a.setup.includes('nfc')) return true
          return false
        })
        if(sa) {
          let ktrim = String(sa.key).toLowerCase().trim();
          result.key = ktrim,
          result.setup = sa.setup,
          result.label = String(sa?.label || ktrim).toUpperCase()
        }
        return result
      }
    },
    methods: {
        async $stopBarcodeScan() {
          await stopBarcodeScan();
        },
        async $barcodeScan({type, cb}) {
          // Add the `barcodeScanned` listener
          const scanResult = await scanSingleBarcode({ type: type || false })
          const barcode = (scanResult || {}).rawValue || null
          console.log('barcode result:', barcode);
          if(barcode) {
            let code = null
            let codeSplit = String(barcode).split('#/code/')
            if(codeSplit[1]) code = codeSplit[1]
            else if([5,6].includes(code[0].length)) {
              console.log('Assuming simple code')
              code = codeSplit[0]
            }
            else {
              return this.setAlert('Invalid barcode format', 'warning')
            }
            let result = {code, point: null, assets: []}

            let tag = this.$store.getters.tagByCode(code)
            if(tag) {
              result.point = tag.point || tag.assetID
              let search = await searchPoint(result.point)
              console.log('search point:', search)
              let assetsCurrent = []
              search.points.filter(p => {
                if(!p.end || p.end === null) assetsCurrent.push(p.aid)
              })
              if(assetsCurrent.length > 0) {
                assetsCurrent.forEach(aid => {
                  result.assets.push(search.assets[aid].assetID)
                })
              }
            }
            if(cb) {
              cb(result)
            }
            else {
              console.warn('SYSTEM ERROR: missing callback', result)
              return false
            }
          }
          else {
            return false
          }
        },
        async $nfcFill({aid, code, cb, once}) {
          // console.warn('nfcFill')
          if(this.$nfc.status === 'notsupported') return
          // console.log(this.$nfc)
          if(this.$nfc.state.autocallback !== null) this.$nfcClearAction()
          this.$nfcCallbackKey = await this.$store.dispatch('nfcSetCallback', {
            auto: true,
            cb(tag, err) {
              if(err) {
                this.setAlert(`${err.message}` + (err.error ? `, error: ${err.error}` : ''), 'warning')
              }
              else {
                console.log('nfcFill, using tag: ', tag)
                if(tag.aid && aid) this[aid] = tag.aid
                if(tag.code && code) this[code] = tag.code
                if(cb) {
                  console.log('run cb()', tag.aid, tag.code)
                  cb({aid: tag.aid, code: tag.code})
                }
              }
              if(once) this.$nfcClearAction()
            }
          })
          // console.log('nfcFill, this.$nfcCallbackKey: ', this.$nfcCallbackKey)
          this.$nfcScan = true
        },
        $nfcToggleFill({aid, code}) {
          if(this.$nfc.state.autocallback === null) this.$nfcFill({aid, code, cb, once})
          else this.$nfcClearAction()
        },
        $nfcClearAction() {
          // console.warn('nfcClearAction')
          this.$store.dispatch('nfcClearCallback', { key: this.$nfcCallbackKey })
          this.$nfcScan = false
        },
    }
  }
