<template>
  <div>
    <!-- BarcodeOverlay moved outside the c-app component -->
    <BarcodeOverlay />
    <div class="c-app" style="background-color: rgb(49, 143, 254); position: relative" :style="{ padding }">
      <NfcScanner />
      <b-modal hideHeader hideFooter id="updateSW" centered scrollable>
        <div v-if="sw.waiting" class="tk-instruct">
          <h2>Trakk has been updated!</h2>
          <p>Hit 'update now' to restart your session.</p>
          <div class="flex-row">
            <div>
              <b-button block @click="$bvModal.hide('updateSW')" variant="warning">Later</b-button>
            </div>
            <div>
              <b-button block @click="refreshApp" variant="info">Update Now</b-button>
            </div>
          </div>
        </div>
        <div v-else class="tk-instruct">
          <h2>System updates</h2>
          <p>No updates were found, check back again later.</p>
          <div style="float: right">
            <b-button @click="$bvModal.hide('updateSW')" variant="warning">OK</b-button>
          </div>
        </div>
      </b-modal>
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import NfcScanner from "@/components/NfcScanner";
import BarcodeOverlay from "@/components/BarcodeOverlay";
import _debounce from 'lodash/debounce'
import Alert from '@/mixins/alert'
import { SafeArea } from 'capacitor-plugin-safe-area';

const TOUR_FILE_DELAY = 10000
const TOUR_PANO_DELAY = 30000
const TOUR_ASSET_DELAY = 10000

export default {
  name: 'App',
  data() {
    return {
      refreshing: false,
      tourTimeout: false,
      tourHistory: [],
      tourPath: false,
      safeInset: { top: 0, right: 0, bottom: 0, left: 0 }
    }
  },
  components: {
    NfcScanner,
    BarcodeOverlay
  },
  mixins: [Alert],
  computed: {
    sw() {
      return this.$store.state.Util.sw
    },
    tour() {
      return this.$store.state.Util.nav.tour
    },
    padding () {
      let top = Math.max(this.safeInset.top, 0)
      let bottom = 0
      return top + 'px ' + this.safeInset.right + 'px ' + bottom + 'px ' + this.safeInset.left + 'px'
    }
  },
  watch: {
    sw: {
      deep: true,
      handler (sw) {
        if(sw.upgrade) this.$bvModal.show('updateSW')
      }
    },
    tour (tour) {
      if(this.tourTimeout) clearTimeout(this.tourTimeout)
      if(tour === true) {
        this.tourHistory = []
        let route = this.$router.currentRoute
        let routeID = route.params.iid || route.params.aid || route.params.pid;
        this.tourRoute = route
        this.tourPath = { name: route.name, id: routeID }
        this.tourTimeout = setTimeout(this.advanceTour, 1500)
      }
    }
  },
  mounted() {
    window.addEventListener('keyup', e => {
      // console.log(e)
      if(e.altKey) {
        let hotKeys = ['KeyT', 'KeyN', 'KeyU']
        if (e.code === 'KeyN') this.$bvModal.show('nfcTest')
        else if (e.code === 'KeyU') this.$bvModal.show('updateSW')
        else if (e.code === 'KeyT') this.toggleTour()
        else if (hotKeys.includes[e.code]) this.$root.$emit('keypress', e.key)
      }
    })
    if (navigator.serviceWorker) {  
      navigator.serviceWorker.addEventListener(
        'controllerchange', () => {
          if (this.refreshing) return;
          this.refreshing = true;
          window.location.reload();
        }
      );
    }
    window.addEventListener('resize', async () => {
      this.checkSafeArea()
    })
    this.checkSafeArea()
  },
  methods: {
    checkSafeArea: _debounce(function () {
      let check = async () => {
        const safeAreaData = await SafeArea.getSafeAreaInsets();
        // console.log('safeAreaData', safeAreaData)
        this.safeInset = safeAreaData.insets
        // this.safeInset.top = 10
      }
      check()
    }, 500),
    refreshApp () {
      this.$bvModal.hide('updateSW');
      if (!this.sw.waiting) { 
        this.$store.commit('SET_ALERT', { 
          notice: "Could not update. Please try again later.", 
          variant: 'warning',
          timeout: 5000
        })
        return console.log('No waiting service worker');
      }
      this.sw.waiting.postMessage('skipWaiting');
    },
    advanceTour() {
      this.tourRoute = this.$router.currentRoute
      let abort = () => {
        this.$store.commit('SET_NAV', { tour: false })
        console.log('TOUR: aborted')
      }
      let tourNext = ({ aid, iid, pid }) => {
        let next = aid || iid || pid;
        if(!next) {
          console.log('SYSTEM ERROR: invalid function call')
          this.setAlert('SYSTEM ERROW, aborting TOUR', 'warning', 5000)
          return abort()
        }
        let path = null
        let delay = null
        if(iid) {
          let image = this.$store.getters.imageByID(iid)
          path = { name: 'IMAGE VIEW', params: { iid } }
          delay = image.type === 'pano' ? TOUR_PANO_DELAY : TOUR_FILE_DELAY;
        }
        else if(aid) {
          path = { name: 'ASSET INFO', params: { aid } }
          delay = TOUR_ASSET_DELAY
        }
        else if(pid) {
          path = { name: 'ASSETS', params: { pid, srch: ' ' } }
          delay = TOUR_ASSET_DELAY
        }
        this.$router.push(path)
        this.tourPath = { name: path.name, id: next }
        this.tourTimeout = setTimeout(this.advanceTour, delay)
      }
      if(this.tourPath) {
        let route = this.tourRoute
        let routeID = route.params.iid || route.params.aid || route.params.pid;
        console.log({tourPath: this.tourPath, route})
        if(route.name !== this.tourPath.name || routeID !== this.tourPath.id) {
          console.log('TOUR: route changed, aborting')
          this.setAlert('Site TOUR paused, press Alt+T to restart', 'info', 5000)
          return abort()
        }
      }
      if(this.tourHistory.length > 100) {
        console.log('TOUR: history > 100, resetting')
        this.tourHistory = []
      }
      let tourLength = this.tourHistory.length
      if(tourLength > 0 && Math.floor(tourLength / 10) * 10 === tourLength) {
        this.setAlert('Site TOUR in progress, press Alt+T to stop', 'info', 5000)
      }
      // console.log('TOUR', { history: this.tourHistory, route })
      
      if(!['ASSETS', 'ASSET INFO', 'IMAGE VIEW'].includes(this.tourRoute.name)) {
        console.log('TOUR: moved out of tour route, aborting')
        this.tourHistory = []
        return this.abort()
      }
      if(this.tourRoute.name === 'IMAGE VIEW') {
        let iid = this.tourRoute.params.iid
        this.tourHistory.push(iid)
        let image = this.$store.getters.imageByID(iid)
        if(!image) return console.warn('SYSTEM ERROR, could not advance tour image not found')
        let hotSpots = image.hotSpots || []
        let nextImage = hotSpots.find(h => {
          if(h.hsType !== 'image') return false
          if(this.tourHistory.includes(h.imageID)) return false
          if(typeof h.imageID !== 'string') return false
          return true
        })
        if(nextImage) {
          console.log('TOUR: found next image', nextImage)
          return tourNext({ iid: nextImage.imageID })
        }
        let assets = image.assets || []
        if(assets.length === 0) {
          this.setAlert('Data error, aborting TOUR', 'warning', 5000)
          return abort()
        }
        let nextAsset = assets.find(a => !this.tourHistory.includes(a))
        if(!nextAsset) nextAsset = assets[0]
        return tourNext({ aid: nextAsset })
      }
      if(this.tourRoute.name === 'ASSET INFO') {
        let aid = this.tourRoute.params.aid
        this.tourHistory.push(aid)
        let asset = this.$store.getters.assetByID(aid)
        if(!asset) return console.warn('SYSTEM ERROR, could not advance tour image not found')
        let images = asset.images || []
        let nextImage = images.find(i => {
          if(this.tourHistory.includes(i)) return false
          if(typeof i !== 'string') return false
          return true
        })
        if(nextImage) return tourNext({ iid: nextImage })
        if(asset.parent) return tourNext({ pid: asset.parent })
        let assetBranch = this.$store.getters.assetBranch(aid)
        let nextAsset = assetBranch.find(a => !this.tourHistory.includes(a))
        if(nextAsset) return tourNext({ aid: nextAsset })
      }
      if(this.tourRoute.name === 'ASSETS') {
        let pid = this.tourRoute.params.pid
        this.tourHistory.push(pid)
        let asset = this.$store.getters.assetByID(pid)
        if(!asset) return console.warn('SYSTEM ERROR, could not advance tour image not found')
        let assetBranch = this.$store.getters.assetBranch(pid)
        let nextAsset = assetBranch.find(a => !this.tourHistory.includes(a))
        if(nextAsset) return tourNext({ aid: nextAsset })
        if(asset.parent) return tourNext({ pid: asset.parent })
      }
      if(this.tourHistory.length > 1) {
        console.log('TOUR: could not resolve tour, trying restart')
        this.tourHistory = []
        return this.advanceTour()
      }
      console.log('TOUR: could not resolve tour, aborting')
      abort()
    },
    toggleTour: _debounce(function() {
      let tour = this.$store.state.Util.nav.tour
      this.tourHistory = []
      this.tourPath = false
      if(tour) {
        this.$store.commit('SET_NAV', { tour: false })
        console.log('Stop tour')
        this.setAlert('Stopped site tour')
      }
      else {
        this.$store.commit('SET_NAV', { tour: true })
        console.log('Start tour')
        this.setAlert('Starting site tour')
      }
    }, 500)
  }
}
</script>

<style lang="scss">
  // Import Main styles for this application
  @import 'assets/scss/style';
</style>
